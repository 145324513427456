import * as React from 'react'
import { Link, HeadFC } from 'gatsby'
import PageTemplate from '../components/templates/PageTemplate'
import DefaultHelmet from '../components/atoms/DefaultHelmet'

const NotFoundPage = () => {
  return (
    <PageTemplate>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 250px)',
        }}
      >
        <h1 style={{ fontSize: '72px', color: 'var(--cc-h)' }}>404</h1>
        <h2>Ups, parece que no hay una página para mostrar</h2>
      </div>
    </PageTemplate>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => (
  <>
    <title>404 - Comic Colombiano</title>
    <meta
      name="description"
      content="¡Novelas gráficas, revistillas, webcómics y más! Conoce aquí el catálogo más grande de cómic colombiano y sus autores. ¡La inscripción es gratuita!"
    />
    <meta
      name="keywords"
      content="colombiano,comic,base,de,datos,comics,webcomics,webcomic,novela,grafica,arte,revistillas,catalogo"
    />
    <DefaultHelmet />
  </>
)
